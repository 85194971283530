import React from "react"
import Layout from "../components/layout"
import Project from "../components/project"
import { graphql } from "gatsby"
import "./projects.scss"
// import * as projectsStyles from "./projects.module.scss"
import { Seo } from "../components/seo"

//rename edges for readability/ease
const ProjectsPage = ({
  data: {
    allMarkdownRemark: { edges: postEdges },
    techLogos: { edges: techLogoEdges },
  },
}) => {
  const techLogos = {}

  techLogoEdges.forEach(({ node }) => {
    techLogos[node.name] = node.publicURL
  })

  // console.log("techLogos: ", techLogos)
  return (
    <div>
      <Layout>
        {/* <div className="projects"> */}
        <div>
          <h1>Projects</h1>
          {postEdges.map(({ node }) => {
            const {
              title,
              description,
              github,
              // demo, TODO: ADD DEMOS
              technologies,
              projectLogo,
            } = node.frontmatter
            return (
              <Project
                key={node.id}
                title={title}
                description={description}
                github={github}
                // demo={demo} TODO: ADD DEMOS
                technologies={technologies}
                techLogos={techLogos}
                projectLogo={projectLogo.publicURL}
              />
            )
          })}
        </div>
      </Layout>
    </div>
  )
}

export default ProjectsPage

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "project" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            description
            github
            technologies
            tags
            projectLogo {
              publicURL
            }
          }
        }
      }
    }
    techLogos: allFile(
      filter: {
        absolutePath: { regex: "/tech-logos/" }
        extension: { regex: "/svg/" }
      }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`

export const Head = () => {
  return <Seo title="Projects" />
}
