import React from "react"
import "./project.scss"
import { FaGithub } from "react-icons/fa"

const Project = ({
  title,
  description,
  github,
  // demo, TODO: ADD DEMOS
  technologies,
  techLogos,
  projectLogo,
}) => {
  return (
    <div className="project">
      <div className="projectPaper">
        <div className="divlogo">
          <img
            className="projectLogo"
            alt="Project logo"
            src={projectLogo}
          />
        </div>
        <div className="projectContentPaper">
          <h2 className="projectTitle">{title}</h2>

          <div className="projectBody">
            <p className="projectBodyDescription">{description}</p>
            <div className="projectBodyTechlogos">
              {technologies.map(technology => {
                return (
                  <div key={technology} className="projectBodyTechlogos__logo">
                    <span className="tooltip">{technology}</span>
                    <img
                      className={`${technology.replace(/\s/g, "-").toLowerCase()}-logo-img`}
                      alt={`${technology} Logo`}
                      src={techLogos[technology.toLowerCase()]}
                    />
                  </div>
                )
              })}
            </div>
            <div className="projectBodyButtons">
              {github && (
                <div className="linkbutton">
                  <a
                    href={github}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaGithub />
                    Source
                  </a>
                </div>
              )}
              {/* TODO: ADD DEMOS (below is test code)*/}
              {/* {github && (
                <div className="linkbutton secondary">
                  <a
                    href="https://www.duckduckgo.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Demo
                  </a>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project
